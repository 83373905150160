<template>
    <div>
        <!-- <div class="wrapper">
        <header>
        <div class="pagesize">
            <div class="header-area">
          <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
            <p class="header-time">更新時間：{{updateTime}}</p>
            <a href="#" class="header-nav-btn"></a>
            <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder on">需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
            </div>
            </div>
        </div>
        </header>
        <div class="container">
        <div class="pagesize">
            <ul class="breadcrumbs">
            <li><a href="#">需量反應</a></li>
            <li><span>即時執行情形</span></li>
            </ul>
            <div class="area js_fullArea bg-white flex-area v-center a-center">
            <div class="padding03">
                <div class="imgbox tC">
                <img src="./images/404-demand.jpg" alt="今日無需量反應事件">
                </div>
            </div>
            </div>
        </div>
        </div>
        <footer>
        <div class="pagesize">
            <div class="footer-info">
            <div class="info-box info-main">
                <div class="logo"></div>
                <div class="copyright">
                <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
                <p>Copyright © All Rights Reserved</p>
                </div>
            </div>
            <div class="info-box info-sub">
                <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
                <div class="logo02"></div>
            </div>
            </div>
        </div>
        </footer>
    </div> -->
    <div class="wrapper">
    <!-- header -->
    <!-- 大網展開menu: header-menu-sec 移除hidden -->
    <!-- 小網展開menu: wrapper 加class="header-nav-open" -->
    <header>
      <div class="pagesize">
        <div class="header-area">
          <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
          <p class="header-time">更新時間：{{updateTime}}</p>
          <a href="#" class="header-nav-btn"></a>
            <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder on" >需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
            </div>
        </div>
      </div>
    </header>
    <!-- container -->
    <div class="container">
      <div class="pagesize">
        <ul class="breadcrumbs">
          <li><a href="#">需量反應</a></li>
          <li><span>即時執行情形</span></li>
        </ul>
        <h3 v-if="errorMsg" style="color:red">{{errorMsg}}</h3>
        <div class="area bg-white margin02">
          <div class="padding03">
            <div class="history-area box-border box-round">
              <div class="flex-box flex-1 padding02 section-box bid-info">
                <div class="section-inner">
                  <div class="icon imgbox">
                    <img src="./images/bid01.png">
                  </div>
                  <div class="inner">
                    <h3 class="subtitle">得標執行時間</h3>
                    <div class="num">
                      <p class="color-orange font-size-xxl weight-bold">{{queryDate}}<br/>{{timeStart}}~{{timeEnd}}</p>
                      <p class="color-orange">(共計2小時)</p>
                    </div>
                  </div>
                </div>
                <div class="section-inner">
                  <div class="icon imgbox">
                    <img src="./images/bid02.png">
                  </div>
                  <div class="inner">
                    <h3 class="subtitle margin">得標價格</h3>
                    <div class="num">
                      <p class="color-green"><span class="font-size-max weight-bold">{{(winBid >= 10) ?  10 : winBid}}</span>元/度</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-box value-board">
                <div class="value-box type01">
                  <h3 class="subtitle">用電基準容量CBL</h3>
                  <p class="num"><span>{{dbDataHandle(CBL)}}</span>kW</p>
                </div>
                <div class="value-box type02">
                  <h3 class="subtitle">目前抑低期間最高需量</h3>
                  <p class="num"><span>{{hourCblMax === null ? 0 : hourCblMax}}</span>kW</p>
                </div>
                <div class="value-box type03">
                  <h3 class="subtitle">目前實際抑低容量</h3>
                  <p class="num"><span>{{hourCblMax === null ? 0 : dbDataHandle(CBL - hourCblMax)}}</span>kW</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- area -->
        <div class="area bg-white margin02">
          <div class="padding03">
            <h2 class="boxtitle">群組即時總用電需量</h2>
            <p class="tR"><span class="inlineIcon time vM"></span>&nbsp;<span class="color-blue-light vM">更新時間：{{upDateTime}}</span></p>
            <div class="imgbox full">
              <Highcharts :options="groupCBLChart" ref="highchart" />
              <!-- <img src="./img/graph01.jpg"> -->
            </div>
          </div>
        </div>
        <!-- area -->
        <div class="area bg-white">
          <div class="padding03">
            <h2 class="boxtitle margin02">各群組成員即時用電需量</h2>
            <ul class="groupInfo-area">
              <li v-for="(i, idx) in siteCharArr" :key="idx">
                <div class="groupInfo-title">
                  <p>{{changeToChineseSite(i.siteName)}}</p>
                </div>
                <div class="groupInfo-inner">
                  <p class="tR"><span class="inlineIcon time vM"></span>&nbsp;<span class="color-blue-light vM font-size-s">更新時間：{{upDateTime}}</span></p>
                  <div class="imgbox full">
                    <Highcharts :options="i.highChart" ref="highchart" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--  footer -->
    <footer>
      <div class="pagesize">
        <div class="footer-info">
          <div class="info-box info-main">
            <div class="logo"></div>
            <div class="copyright">
              <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
              <p>Copyright © All Rights Reserved</p>
            </div>
          </div>
          <div class="info-box info-sub">
            <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
            <div class="logo02"></div>
          </div>
        </div>
      </div>
    </footer>
  </div>
    </div>
</template>

<style scoped>
    @import './css/inner.css';
    .cursor > g{
      cursor: pointer;
    }
</style>

<script>
import { mapMutations } from 'vuex'
import { dbDataHandle } from '../store/function/index'
import progressbar from './js/jquery.progressbar'
import importJQ from './js/main'
import { DateTime } from 'luxon'
import axios from 'axios'
import cookies from 'vue-cookies'
export default {
  data () {
    return {
      siteFilterArr: ['SHILIN', 'TAIPEI_ASTRONOMICAL_MUSEUM', 'SHILIN_MARKET', 'SHILIN_PRECINCT_POLICE_STATION', 'SHIDONG_MARKET', 'SHILIN_SPORTS_CENTER'],
      chineseSite: {
        SHILIN: '士林區行政中心',
        TAIPEI_ASTRONOMICAL_MUSEUM: '天文科學教育館',
        SHILIN_MARKET: '士林市場',
        SHILIN_PRECINCT_POLICE_STATION: '士林分局',
        SHIDONG_MARKET: '士東市場',
        SHILIN_SPORTS_CENTER: '士林運動中心'
      },
      errorMsg: '',
      pendingMessage: '',
      updateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
      siteCharArr: [],
      queryCBLh: '',
      queryCBL: '',
      queryDate: '',
      timeStart: '',
      timeEnd: '',
      CBL: '',
      hourCblMax: null,
      upDateTime: '',
      winBid: '',
      todayEvent: true,
      reCatch: null,
      groupCBLChart: {
        chart: {
          backgroundColor: null
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: ['7:00', '8:00', '9:00']
        },
        yAxis: [
          {
            title: {
              text: ''
            }
          },
          {
            title: {
              text: ''
            },
            opposite: true
          },
          {
            title: {
              text: ''
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          align: 'center',
          layout: 'horizontal',
          // floating: true,
          verticalAlign: 'bottom',
          x: 0,
          y: 0
        },
        series: [
          {
            type: 'area',
            yAxis: 0,
            name: '得標時段15分鐘需量(kW)',
            data: [],
            color: '#a3dce3'
          },
          {
            type: 'spline',
            yAxis: 0,
            name: 'CBL(kW)',
            data: [],
            color: '#ef7e7e'
          },
          {
            type: 'area',
            yAxis: 0,
            name: '非得標時段15分鐘需量(kW)',
            data: [],
            color: '#1a9faa'
          },
          {
            type: 'spline',
            yAxis: 0,
            name: '抑低成功門檻',
            data: [],
            color: '#fdba21'
          }
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'LOGIN_OUT'
    ]),
    dbDataHandle (num, index) {
      return dbDataHandle(num, index)
    },
    changeToChineseSite (str) {
      return this.chineseSite[`${str}`]
    },
    async winBid15Dmd () {
      const token = cookies.get('Authorization')
      const queryCBLh = await axios.post('/api/cems/winBid15Dmd',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (queryCBLh.data.success) {
        const basicData = queryCBLh.data.dataDetail
        this.queryDate = basicData.date
        this.timeStart = basicData.timeStart
        this.timeEnd = basicData.timeEnd
        this.winBid = basicData.winBid
        if (!queryCBLh.data.isFuture) {
          this.queryCBLh = queryCBLh.data.data.filter(x => this.siteFilterArr.includes(x.ems))
          await this.getqueryCBL(this.queryDate, this.timeStart, this.timeEnd)
          this.buildCBLChart()
        }
      } else {
        if (this.errorMsg !== '') {
          this.errorMsg = this.errorMsg + ' ' + queryCBLh.data.msg
        } else {
          this.errorMsg = queryCBLh.data.msg
        }
      }
    },
    async getqueryCBL (queryDate, timeStart, timeEnd) {
      const token = cookies.get('Authorization')
      const queryCBL = await axios.post('/api/cems/queryCBL',
        {
          queryDate,
          timeStart,
          timeEnd
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (queryCBL.data.success) {
        this.queryCBL = queryCBL.data.data.filter(x => this.siteFilterArr.includes(x.ems)).groupBy('ems')
      } else {
        if (this.errorMsg !== '') {
          this.errorMsg = this.errorMsg + ' ' + queryCBL.data.msg
        } else {
          this.errorMsg = queryCBL.data.msg
        }
      }
    },
    async buildCBLChart () {
      this.groupCBLChart.xAxis.categories = []
      this.groupCBLChart.series[0].data = []
      this.groupCBLChart.series[1].data = []
      this.groupCBLChart.series[2].data = []
      this.groupCBLChart.series[3].data = []

      let time = DateTime.fromObject({ hour: this.timeStart.slice(0, 2), minute: this.timeStart.slice(-2), second: 0, zone: 'Asia/Taipei' })
      time = time.minus({ minute: 30 })
      for (let i = 0; i <= 180; i++) {
        this.groupCBLChart.xAxis.categories.push(time.toFormat('HH:mm'))
        this.groupCBLChart.series[0].data.push(null)
        this.groupCBLChart.series[2].data.push(null)
        time = time.plus({ minute: 1 })
      }
      this.chartInherit()
      this.buildTotalCBLChart()
      this.buildEachChart()
    },
    buildEachChart () {
      this.siteCharArr.forEach(x => {
        let queryDays = 0
        let CBL = 0
        for (const [index, [keys, value]] of Object.entries(Object.entries(this.queryCBL))) {
          const filterSameDay = this.dedup(value)
          queryDays = Number(index) === 0 ? filterSameDay.length : queryDays
          if (x.siteName === keys) {
            filterSameDay.forEach(x => {
              if (x && x.meanDemand) CBL = (CBL + x.meanDemand)
            })
          }
        }
        CBL = CBL / queryDays
        x.highChart.xAxis.categories.forEach(y => {
          x.highChart.series[1].data.push(this.dbDataHandle(CBL))
        })
        this.queryCBLh.forEach(z => {
          if (z.ems === x.siteName) {
            z.resources.forEach(y => {
              const index = x.highChart.xAxis.categories.findIndex(z => z === y.timestamp)
              if (index > 0 && y) {
                if (y.timestamp < this.timeStart || y.timestamp > this.timeEnd) {
                  x.highChart.series[2].data[index] > 0 ? x.highChart.series[2].data[index] += y.demand_15 : x.highChart.series[2].data[index] = y.demand_15
                } else if (y.timestamp === this.timeStart || y.timestamp === this.timeEnd) {
                  x.highChart.series[2].data[index] > 0 ? x.highChart.series[2].data[index] += y.demand_15 : x.highChart.series[2].data[index] = y.demand_15
                  x.highChart.series[0].data[index] > 0 ? x.highChart.series[0].data[index] += y.demand_15 : x.highChart.series[0].data[index] = y.demand_15
                } else {
                  x.highChart.series[0].data[index] > 0 ? x.highChart.series[0].data[index] += y.demand_15 : x.highChart.series[0].data[index] = y.demand_15
                }
              }
            })
          }
        })
      })
    },
    buildTotalCBLChart () {
      this.CBL = 0
      let queryDays = 0
      for (const [index, [, value]] of Object.entries(Object.entries(this.queryCBL))) {
        const filterSameDay = this.dedup(value)
        queryDays = Number(index) === 0 ? filterSameDay.length : queryDays
        filterSameDay.forEach(x => {
          if (x && x.meanDemand) this.CBL = (this.CBL + x.meanDemand)
        })
      }
      this.CBL = this.CBL / queryDays

      for (let i = 0; i < this.groupCBLChart.xAxis.categories.length; i++) {
        this.groupCBLChart.series[1].data.push(this.dbDataHandle(this.CBL))
        this.dbDataHandle(this.CBL - 100) > 0 ? this.groupCBLChart.series[3].data.push(this.dbDataHandle(this.CBL - 100)) : this.groupCBLChart.series[3].data.push(null)
      }
      this.queryCBLh.forEach(x => {
        x.resources.forEach(y => {
          const index = this.groupCBLChart.xAxis.categories.findIndex(z => z === y.timestamp)
          if (index > 0 && y) {
            if (y.timestamp < this.timeStart || y.timestamp > this.timeEnd) {
              this.groupCBLChart.series[2].data[index] > 0 ? this.groupCBLChart.series[2].data[index] += y.demand_15 : this.groupCBLChart.series[2].data[index] = y.demand_15
            } else if (y.timestamp === this.timeStart || y.timestamp === this.timeEnd) {
              this.groupCBLChart.series[2].data[index] > 0 ? this.groupCBLChart.series[2].data[index] += y.demand_15 : this.groupCBLChart.series[2].data[index] = y.demand_15
              this.groupCBLChart.series[0].data[index] > 0 ? this.groupCBLChart.series[0].data[index] += y.demand_15 : this.groupCBLChart.series[0].data[index] = y.demand_15
            } else {
              this.groupCBLChart.series[0].data[index] > 0 ? this.groupCBLChart.series[0].data[index] += y.demand_15 : this.groupCBLChart.series[0].data[index] = y.demand_15
            }
          }
        })
      })
      this.hourCblMax = Math.max(...this.groupCBLChart.series[0].data)
      this.upDateTime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss')
    },
    dedup (arr) {
      const hashTable = {}
      return arr.filter(x => {
        const key = JSON.stringify(x)
        const match = Boolean(hashTable[key])
        const res = match ? false : hashTable[key] = true
        return res
      })
    },
    async reserverateProgress () {
      const date = DateTime.local().toFormat('yyyy-MM-dd')
      const data = await axios.get(`https://tpcems.tier.org.tw/api/tpcems/open/home/operating-reserve-by-date?date=${date}`)
      var barValue = data.data.operatingReserveRate // 設定數值顯示文字
      var bar = new progressbar.SemiCircle('.SemiCircleBar', {
        strokeWidth: 6,
        color: '#c0ff37',
        trailColor: '#fff',
        trailWidth: 6,
        easing: 'easeInOut',
        duration: 1000,
        svgStyle: null,
        text: {
          value: ''
          // alignToBottom: true
        },
        step: function (state, circle) {
          circle.setText(barValue)
        }
      })
      bar.animate(0.3) /* b設定bar參數  0~1.0 */
    },
    chartInherit () {
      const siteArray = Object.keys(this.queryCBL)
      this.siteCharArr = []
      siteArray.forEach(x => {
        const chart = JSON.parse(JSON.stringify(this.groupCBLChart))
        chart.legend.enabled = false
        chart.chart.height = '200'
        this.siteCharArr.push({ highChart: chart, siteName: x })
      })
    }
  },
  async mounted () {
    // eslint-disable-next-line no-extend-native
    Array.prototype.groupBy = function (prop) {
      return this.reduce(function (groups, item) {
        const val = item[prop]
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {})
    }
    importJQ()
    await this.winBid15Dmd()
    this.reCatch = setInterval(async () => {
      await this.winBid15Dmd()
    }, 300000)
  },
  beforeDestroy () {
    clearInterval(this.reCatch)
    this.reCatch = null
  },
  computed: {

  }
}
</script>
